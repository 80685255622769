import { useCallback, useMemo } from 'react';
import { FormLayout } from '../layout/FormLayout';
import * as Yup from 'yup';
import { BVNField } from '../../base/BVNField';
import moment from 'moment';
import { DateField } from '../../base/DateField';
import { Spacer } from '../../base/Spacer';
import useApplicationStore from '../../../state';
import toast from 'react-hot-toast';
import { PhoneField } from '../../base/PhoneField';
import { useTranslation } from '@lendsqr/lingua-react';

function BVNValidation() {
  const [
    bnpl_values,
    verifyBVN,
    createBVN,
    loading,
    bnpl_conf,
    country_code,
    back,
  ] = useApplicationStore((state) => [
    state.bnpl_values,
    state.verifyBVN,
    state.createBVN,
    state.loading,
    state.bnpl_conf,
    state.country_code,
    state.back,
  ]);

  const { translate } = useTranslation();

  const validation = useMemo(
    () =>
      Yup.object({
        bvn: Yup.string()
          .min(11, 'Please enter a valid bvn')
          .max(11, 'Please enter a valid bvn')
          .required('Please enter a bvn'),
        dob: Yup.date().required('Please enter your DOB'),
        bvn_phone_number: Yup.string()
          .min(6, translate('lender-web-app-phone-min'))
          .max(20, translate('lender-web-app-phone-max'))
          .required(translate('lender-web-app-phone-number-error')),
      }),
    []
  );

  const onSubmit = useCallback(async (values: any) => {
    try {
      const bvnMatches = await verifyBVN(
        {
          bvn: values?.bvn.toString(),
          dob: values.dob,
          email: bnpl_values?.email_address,
          last_five_digits: values.bvn_phone_number?.slice(-5),
          phone_number: bnpl_values?.phone_number.replace('+', ''),
        },
        bnpl_values?.phone_number
      );
      if (!bvnMatches) {
        toast.error('BVN does not match');
        return;
      }

      await createBVN(
        {
          phone: values?.last_five,
          dob: values?.dob,
          bvn_phone_number: values?.bvn_phone_number,
          password: bnpl_values?.password,
        },
        'verify'
      );
    } catch (error) {
      console.error(error);
    }
  }, []);

  return (
    <FormLayout
      initialValues={{ ...bnpl_values }}
      validationSchema={validation}
      onSubmit={onSubmit}
      onBack={back}
      loading={loading}
      title={
        bnpl_conf?.identity_validation?.description ??
        'Your BVN does not give us access to your account, we can only access your full name, mobile number and date of birth.'
      }
    >
      <Spacer height={20} />

      <BVNField label="BVN" name="bvn" />

      <Spacer height={32} />

      <DateField
        maxDate={moment().subtract(18, 'years')}
        label="Date of birth"
        name="dob"
      />

      <Spacer height={32} />

      <PhoneField
        value={''}
        country_code={country_code}
        name="bvn_phone_number"
        label="Phone Number (linked to your BVN)"
      />
    </FormLayout>
  );
}

export { BVNValidation };
